var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('H2', [_vm._v("Order records")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.preview
    }
  }, [_c('span', [_vm._v(" Preview ")])]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.send
    }
  }, [_c('span', [_vm._v(" Send ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }