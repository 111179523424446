import axios from '@axiosV2'

const preview = params =>
  axios.get('/admin/reward-point-logs/manual-export/storage/preview', {
    params,
  })

const send = data => axios.post('/admin/reward-point-logs/manual-export/storage/send', data)

export default {
  preview,
  send,
}
