var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filter-section', {
    attrs: {
      "cols": [3, 3, 3, 3]
    }
  }, [_c('distributor-id-filter', {
    attrs: {
      "slot": "col_0"
    },
    slot: "col_0",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('created-at-from-filter', {
    attrs: {
      "slot": "col_1"
    },
    slot: "col_1",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('created-at-to-filter', {
    attrs: {
      "slot": "col_2"
    },
    slot: "col_2",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('div', {
    attrs: {
      "slot": "col_3"
    },
    slot: "col_3"
  })], 1), _c('v-card', [_c('v-card-title', [_vm._v(" Preview & resent Distributor report ")]), _c('v-card-text', [_c('ExportRewardLog', {
    attrs: {
      "filters": _vm.filters
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }