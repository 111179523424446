<template>
  <div>
    <H2>Order records</H2>
    <v-btn
      color="primary"
      :loading="loading"
      @click="preview"
    >
      <span>
        Preview
      </span>
    </v-btn>

    <v-btn
      :loading="loading"
      @click="send"
    >
      <span>
        Send
      </span>
    </v-btn>
  </div>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { ref } from '@vue/composition-api'
import { rewardPointLogExportStorageService } from '../../../services'

export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)

    const preview = () => {
      loading.value = true
      rewardPointLogExportStorageService.preview(props.filters).then(() => {
        useNotifySuccess({ content: 'Preview Success, see the Network Tab' })
      }).catch(useNotifyErrors).finally(() => {
        loading.value = false
      })
    }

    const send = () => {
      loading.value = true
      rewardPointLogExportStorageService.send(props.filters).then(() => {
        useNotifySuccess({ content: 'Send Success.' })
      }).catch(useNotifyErrors).finally(() => {
        loading.value = false
      })
    }

    return {
      loading,
      preview,
      send,
    }
  },
}
</script>
